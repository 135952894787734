import React, { useCallback, useState } from "react";
import headerStyles from "./header.module.css";
import { Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { navigate } from "@reach/router" 
import clsx from "clsx";

const imageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "websitelogosandcolorscheme/ssa-logo-website-transparent-gold-foil.png" }) {
      childImageSharp {
        fixed(height: 95) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    instagramGold: file(relativePath: { eq: "websitelogosandcolorscheme/Instagram-gold-icon-transparent.png" }) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    facebookGold: file(relativePath: { eq: "websitelogosandcolorscheme/facebook-gold-icon-transparent.png" }) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const ListLink = (props) => (
  <li style={{ display: "inline-block", marginRight: "1rem" }} className={props.listClassName} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    <Link to={props.to} className={props.className} activeClassName={props.activeClassName}>
      {props.children}
    </Link>
  </li>
);

export default function Header(props) {
  const image = useStaticQuery(imageQuery);
  const isLargeScreen = useMediaQuery("(min-width:980px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [servicesMenuRef, setServicesMenuRef] = useState(null);
  const [servicesHoverTimeout, setServicesHoverTimeout] = useState(null);

  const navigateToUrl = useCallback((url) => {
    navigate(url);
    setServicesMenuRef(null);
  }, []);

  return (
    <div style={{ width: "100%", height: "100px", float: "left", position: "fixed", zIndex: 3 }}>
      <header
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          backgroundColor: "#e9f0f3",
          lineHeight: "50px",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{ textShadow: `none`, backgroundImage: `none`, display: "flex", alignItems: "center", height: "100%" }}
          className={clsx(headerStyles.link, headerStyles.logo)}
        >
          <Img
            style={{ overflow: "visible" }}
            fixed={image.logo.childImageSharp.fixed}
            imgStyle={{ objectFit: "contain" }}
          />
        </Link>
        {isLargeScreen && (
          <ul className={headerStyles.largeList} style={{ listStyle: `none`, float: `right`, margin: 0 }}>
            <ListLink to="/" className={headerStyles.link} activeClassName={headerStyles.active}>
              Home
            </ListLink>
            <ListLink to="/about/" className={headerStyles.link} activeClassName={headerStyles.active}>
              About
            </ListLink>
            <ListLink 
              to="/services/" 
              className={headerStyles.link} 
              activeClassName={headerStyles.active}
              onMouseEnter={(event) => { setServicesMenuRef(event.currentTarget); }}
              onMouseLeave={() => {
                setServicesHoverTimeout(
                  setTimeout(() => {
                    setServicesMenuRef(null); 
                  }, 200)
                );
              }}
            >
              Services
            </ListLink>
            <Popper open={Boolean(servicesMenuRef)} anchorEl={servicesMenuRef} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={headerStyles.paper}>
                <ClickAwayListener onClickAway={() => { setServicesMenuRef(null); }}>
                  <MenuList 
                    autoFocusItem={Boolean(servicesMenuRef)} 
                    id="menu-list-grow" 
                    onMouseOver={() => {
                      if (servicesHoverTimeout) {
                        clearTimeout(servicesHoverTimeout);
                        setServicesHoverTimeout(null);
                      }
                    }}
                    onMouseLeave={() => { setServicesMenuRef(null); }}
                    className={headerStyles.menu}
                    >
                    <MenuItem onClick={() => { navigateToUrl('/services#botox') }} className={headerStyles.menuListItem}>Botox</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#fillers') }} className={headerStyles.menuListItem}>Fillers</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#kybella') }} className={headerStyles.menuListItem}>Kybella</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#microneedling') }} className={headerStyles.menuListItem}>Microneedling</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#prfm') }} className={headerStyles.menuListItem}>PRFM</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#laser') }} className={headerStyles.menuListItem}>Laser</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#dermaplaning') }} className={headerStyles.menuListItem}>Dermaplaning</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#chemical_peels') }} className={headerStyles.menuListItem}>Chemical Peels</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#diamond_glow') }} className={headerStyles.menuListItem}>Diamond Glow</MenuItem>
                    <MenuItem onClick={() => { navigateToUrl('/services#facials') }} className={headerStyles.menuListItem}>Facials</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
            <ListLink to="/bookonline/" className={headerStyles.link} activeClassName={headerStyles.active}>
              Book Online
            </ListLink>
            <ListLink to="https://squareup.com/gift/FG5S2X7QNENAB/order" className={headerStyles.link} activeClassName={headerStyles.active}>
              Gift Cards <span className={headerStyles.externalLink}><FaExternalLinkSquareAlt /></span>
            </ListLink>
            <li style={{ display: "inline-block", marginRight: "1rem" }}>
              <a href="/servicesbrochure.pdf" target="_blank" className={headerStyles.link}>
                Prices <span className={headerStyles.externalLink}><FaExternalLinkSquareAlt /></span>
              </a>
            </li>
            <ListLink to="https://app.patientfi.com/v2/skinsolutionsaesthetics/apply" className={headerStyles.link} activeClassName={headerStyles.active}>
              Financing <span className={headerStyles.externalLink}><FaExternalLinkSquareAlt /></span>
            </ListLink>
            <ListLink to="/contact/" className={headerStyles.link} activeClassName={headerStyles.active}>
              Contact
            </ListLink>
            <a href="https://www.instagram.com/skinsolutionsaesthetics/" className={headerStyles.socialLinks}>
              <Img
                style={{ overflow: "visible" }}
                fixed={image.instagramGold.childImageSharp.fixed}
                imgStyle={{ objectFit: "fill" }}
              />
            </a>
            <a href="https://www.facebook.com/skinsolutions/" className={headerStyles.socialLinks}>
              <Img
                style={{ overflow: "visible" }}
                fixed={image.facebookGold.childImageSharp.fixed}
                imgStyle={{ objectFit: "fill" }}
              />
            </a>
          </ul>
        )}
        {!isLargeScreen && (
          <>
            <div className={headerStyles.hamburger__container}>
              <button
                onClick={() => {
                  setIsDrawerOpen((openState) => !openState);
                }}
                className={clsx(headerStyles.hamburger, headerStyles.hamburger__arrow, {
                  [headerStyles.is__active]: isDrawerOpen,
                })}
              >
                <span className={headerStyles.hamburger__box}>
                  <span className={headerStyles.hamburger__inner}></span>
                </span>
              </button>
            </div>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
            >
              <ul style={{ listStyle: `none`, display: "flex", flexDirection: "column", margin: 0 }}>
                <ListLink
                  to="/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  Home
                </ListLink>
                <ListLink
                  to="/about/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  About
                </ListLink>
                <ListLink
                  to="/services/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  Services
                </ListLink>
                <ListLink
                  to="/bookonline/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  Book Online
                </ListLink>
                <ListLink
                  to="https://squareup.com/gift/FG5S2X7QNENAB/order"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  Gift Cards <span className={headerStyles.externalLink}><FaExternalLinkSquareAlt /></span>
                </ListLink>
                <ListLink
                  to="https://app.patientfi.com/v2/skinsolutionsaesthetics/apply"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  Financing <span className={headerStyles.externalLink}><FaExternalLinkSquareAlt /></span>
                </ListLink>
                <li style={{ display: "inline-block", marginRight: "1rem" }} className={headerStyles.drawerLink}>
                  <a href="/servicesbrochure.pdf" target="_blank" className={headerStyles.link}>
                    Prices <span className={headerStyles.externalLink}><FaExternalLinkSquareAlt /></span>
                  </a>
                </li>
                <ListLink
                  to="/contact/"
                  listClassName={headerStyles.drawerLink}
                  className={headerStyles.link}
                  activeClassName={headerStyles.active}
                >
                  Contact
                </ListLink>
              </ul>
            </Drawer>
          </>
        )}
      </header>
      {props.children}
    </div>
  );
}
